import React from 'react';
import PropTypes from 'prop-types';
import brain from '../img/icons/brain.svg';
import target from '../img/icons/target.svg';
import earth from '../img/icons/earth.svg';
import bulb from '../img/icons/bulb.svg';
import emotionalResilience from '../img/icons/emotional-resilience.svg';
import optimism from '../img/icons/optimism.svg';
import positiveSelfImage from '../img/icons/positive-self-image.svg';
import selfConfidence from '../img/icons/self-confidence.svg';

const SkillsList = {
    memory: {
        img: brain,
        title: 'Memory',
        alt: 'Memory'
    },
    focus: {
        img: target,
        title: 'Focus',
        alt: 'Focus'
    },
    creativity: {
        img: bulb,
        title: 'Creativity',
        alt: 'Creativity'
    },
    explore: {
        img: earth,
        title: 'Explore the World',
        alt: 'Explore the World'
    },
    'Emotional Resilience': {
        img: emotionalResilience,
        title: 'Emotional Resilience',
        alt: 'Emotional Resilience'
    },
    Optimism: {
        img: optimism,
        title: 'Optimism',
        alt: 'Optimism'
    },
    'Positive Self-Image': {
        img: positiveSelfImage,
        title: 'Positive Self-Image',
        alt: 'Positive Self-Image',
    },
    'Self-Confidence': {
        img: selfConfidence,
        title: 'Self-Confidence',
        alt: 'Self-Confidence',
    }
};

const Skills = ({ group }) => {
    const validSkills = group.filter(skill => Object.prototype.hasOwnProperty.call(SkillsList, skill));

    return (
        <ul className="product-skills">
            {validSkills.map((skill) => (
                <li key={skill}>
                    <span className="has-tooltip-arrow" data-tooltip={SkillsList[skill].title}>
                        <img
                            src={SkillsList[skill].img}
                            alt={SkillsList[skill].alt}
                            className="icon is-large"
                        />
                    </span>
                </li>
            ))}
        </ul>
    );
};

Skills.propTypes = {
    group: PropTypes.arrayOf(PropTypes.string),
};

export default Skills;
