import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {Link, graphql, StaticQuery, navigate} from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import Carousel from './Carousel';
import { AddToCart } from './AddToCart';
import { ProductLink } from '../utils/product';
import {format} from '../utils/format';

const ProductRollTemplate = (props) => {
    const [productList, setProducts] = useState([]);
    const { data } = props;
    const { edges: products } = data.allSitterooShopifyProduct;

    const columnClasses = 'product-roll card m-3 p-3';

    useEffect( () =>
        setProducts(products.map(({ node: product }) => (
            <div className={columnClasses} key={product.shopifyId}>
                <div className="card-image">
                    <figure className="image">
                        <PreviewCompatibleImage
                            imageInfo={{
                                childImageSharp: product.media[0].image.localFile,
                                alt: `Image for ${product.title}`,
                                image: product.media[0].image.localFile,
                                width: product.media[0].image.localFile.childImageSharp.gatsbyImageData.width,
                                height: product.media[0].image.localFile.childImageSharp.gatsbyImageData.height,
                            }}
                        />
                    </figure>
                </div>
                <div className="card-content">
                    <div className="media">
                        <div className="media-content text-cut">
                            <h3>
                                <Link
                                    to={ProductLink(product)}
                                >
                                    {product.title}
                                </Link>
                            </h3>
                        </div>
                    </div>
                    <div className="content">
                        <p className="has-text-centered sitteroo-blog-list-heading">
                            {product.title.match(/Birth Certificate|Magic Star Map/gm) ? null :
                                format(
                                    product.priceRangeV2.minVariantPrice.currencyCode,
                                    product.priceRangeV2.minVariantPrice.amount,
                                )
                            }
                        </p>
                    </div>
                </div>
                <footer className="card-footer p-2">
                    <div className="card-footer-item is-justify-content-center">
                        {product.title.match(/Birth Certificate|Magic Star Map/gm) ?
                            (
                                <button
                                    type="submit"
                                    className="sitteroo-button bg-grey"
                                    onClick={() => {
                                        navigate(ProductLink(product))
                                    }}
                                >
                                Customise
                                </button>
                            )
                            :
                            (
                                <AddToCart
                                    variantId={product.variants[0].storefrontId}
                                    variantName={product.variants[0].displayName}
                                    variantPrice={product.variants[0].price}
                                    quantity={1}
                                    available
                                    customAttr={[]}
                                />
                            )
                        }
                    </div>
                </footer>
            </div>
        ))), [products]);


    return (
        <Carousel
            slides={productList}
            disableButtonsControls={false}
            disableDotsControls
            infinite
            responsive={{
                0: {
                    items: 1,
                },
                768: {
                    items: 2,
                },
                1080: {
                    items: 4,
                },
            }}
        />
    )

};

ProductRoll.propTypes = {
    data: PropTypes.shape({
        allSitterooShopifyProduct: PropTypes.shape({
            // eslint-disable-next-line react/forbid-prop-types
            edges: PropTypes.array,
        }),
    }),
};

export default function ProductRoll() {
    return (
        <StaticQuery
            query={graphql`
                {
                    allSitterooShopifyProduct(sort: {fields: title, order: ASC}, limit: 10) {
                    edges {
                      node {
                        handle
                        title
                        shopifyId
                        media {
                          ... on SitterooShopifyMediaImage {
                            id
                            image {
                              src
                              localFile {
                                childImageSharp {
                                  gatsbyImageData(height: 150, layout: CONSTRAINED, placeholder: BLURRED)
                                }
                              }
                            }
                          }
                        }
                        priceRangeV2 {
                          maxVariantPrice {
                            amount,
                            currencyCode
                          }
                          minVariantPrice {
                            amount,
                            currencyCode
                          }
                        }
                        storefrontId
                        variants {
                           id
                          displayName
                          storefrontId
                          price
                        }
                      }
                    }
                  }
                }
            `}
            render={(data, count) => (
                <ProductRollTemplate data={data} count={count} />
            )}
        />
    );
}
