/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { convertSchemaToHtml } from '@thebeyondgroup/shopify-rich-text-renderer';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import Layout from '../components/Layout';
import {format, metaDescription} from '../utils/format';
import {BlogPostTemplate} from './blog-post';
import {AddToCart} from '../components/AddToCart';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import ProductRoll from '../components/ProductRoll';
import Skills from '../components/Skills';

const ProductTemplate = ({title, subtitle, details, price, images, content, variant, tags, presentmentPrices}) => {
    const [selectedImage, setSelectedImage] = useState(images[0]);
    const [qty, setQty] = useState(1);

    const handleQtyChange = (event) => {
        const {
            value,
        } = event.target;

        setQty(parseInt(value));
    };

    const priceFormatted = format(price?.minVariantPrice?.currencyCode, price?.minVariantPrice?.amount);
    const specialPrice = (presentmentPrices?.compareAtPrice) && format(price?.minVariantPrice?.currencyCode, presentmentPrices.compareAtPrice?.amount);

    return (
        <div className="product pt-5">

            <section className="container">

                <div className="columns is-variable is-1-mobile is-3 is-8-widescreen is-2-fullhd">

                    <div className="column is-half">

                        <div className="card">
                            <div className="card-image">
                                <figure className="image">
                                    <PreviewCompatibleImage
                                        imageInfo={{
                                            childImageSharp: selectedImage?.image.localFile,
                                            alt: `Image for ${title}`,
                                            image: selectedImage?.image.localFile,
                                            width: selectedImage.image.localFile.childImageSharp.gatsbyImageData.width,
                                            height: selectedImage.image.localFile.childImageSharp.gatsbyImageData.height,
                                        }}
                                    />
                                </figure>

                                <div className="product-image-preview">
                                    <div className="media">
                                        {
                                            images.map(thumb => (
                                                <div
                                                    key={thumb.id}
                                                    onClick={() => setSelectedImage(thumb)}
                                                    onKeyDown={() => setSelectedImage(thumb)}
                                                    role='button'
                                                    tabIndex={0}
                                                >
                                                    <figure className="image p-2 thumbnail">
                                                        <PreviewCompatibleImage
                                                            imageInfo={{
                                                                childImageSharp:  thumb?.preview?.image.localFile,
                                                                alt:    `Thumbnail for ${title}`,
                                                                image: thumb?.preview?.image.localFile,
                                                                width: thumb?.preview?.image.localFile.childImageSharp.gatsbyImageData.width,
                                                                height: thumb?.preview?.image.localFile.childImageSharp.gatsbyImageData.height,
                                                            }}
                                                        />
                                                    </figure>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column sky-map-form has-text-left">
                        <h1 className="is-size-2 is-size-3-mobile">{title}</h1>
                        <h2 className="is-size-7">
                            {subtitle}
                        </h2>

                        <p className="is-size-4 mt-5">
                            <s>{ specialPrice }</s>
                            <span className="yellow">{ priceFormatted }</span>
                        </p>

                        <div className="icons-container">
                            <Skills group={tags} />
                        </div>

                        <div className="content is-size-6 mt-3" dangerouslySetInnerHTML={{__html: details }} />

                        <div className="columns">
                            <div className="column is-one-quarter-desktop">
                                <label htmlFor="qty">
                                    <input
                                        type="number"
                                        className="input is-medium has-text-centered"
                                        name="quantity"
                                        id="qty"
                                        aria-label="Quantity"
                                        min="0"
                                        max="10"
                                        onChange={handleQtyChange}
                                        required
                                        placeholder="Quantity"
                                    />
                                </label>
                            </div>

                            <div className="column">
                                <AddToCart
                                    variantId={variant.storefrontId}
                                    variantName={variant.displayName}
                                    variantPrice={variant.price}
                                    quantity={qty}
                                    customAttr={[]}
                                    available
                                    disabled={qty === 0}
                                />
                            </div>

                        </div>
                    </div>

                </div>
                <div className="columns">
                    <div className="column">
                        <div className="description" dangerouslySetInnerHTML={{__html: content}}/>
                    </div>
                </div>

            </section>

            <section className="container">
                <h2 className="sitteroo-monday-picnic yellow has-text-left pt-4">
                    More products
                </h2>
                <ProductRoll />
            </section>
        </div>
    );
}

BlogPostTemplate.defaultProps = {
    images: [],
    price: {},
}


BlogPostTemplate.propTypes = {
    price: PropTypes.object,
    images: PropTypes.array,
    description: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    details: PropTypes.string,
    helmet: PropTypes.object,
};

const Product = ({ pageContext }) => {
    const { product: { title, description, media, priceRangeV2, storefrontId, metafields, descriptionHtml, variants , tags, presentmentPrices} } = pageContext;

    const subtitle = metafields.find((obj) => obj.key === 'subtitle');
    const details  = metafields.find((obj) => obj.key === 'description_text');
    const parsedDetails  = (details?.value && convertSchemaToHtml(details?.value)) || '';

    return (
        <Layout>
            <Helmet titleTemplate="%s">
                <title>{`${title}`}</title>
                <meta
                    name="description"
                    content={`${metaDescription(description)}`}
                />
                <meta
                    name="og:description"
                    content={`${metaDescription(description)}`}
                />
            </Helmet>

            <ProductTemplate
                title={title}
                description={description}
                images={media}
                price={priceRangeV2}
                presentmentPrices={presentmentPrices}
                subtitle={subtitle?.value}
                details={parsedDetails}
                storefrontId={storefrontId}
                content={descriptionHtml}
                variant={variants[0]}
                tags={tags}
            />
        </Layout>
    );
};

Product.propTypes = {
    pageContext: PropTypes.object,
};

export default Product;
